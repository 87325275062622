import * as React from "react";
import { Box, Text } from "@chakra-ui/react";

export default function Sidebar() {
  return (
    <Box
      overflow="hidden"
      fontFamily="satoshi"
      position="absolute"
      top={0}
      left={0}
      w={100}
      h="130vh"
      bgColor="#060616"
      color="#fff"
      fontWeight={700}
      display="grid"
      justifyContent="center"
      className="sidebar"
    >
      <Text
        transform="rotate(-90deg)"
        width="250px"
        margin="auto"
        textAlign="center"
        fontSize="24px"
        letterSpacing="1.2px"
        top="-140px"
        position="relative"
      >
        GFT Production ©
      </Text>
    </Box>
  );
}
