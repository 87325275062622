import React from "react";
import Layout from "./src/components/Layout";
import "lazysizes";

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

// export const onClientEntry = () => {
//   setTimeout(() => {
//     if (document.getElementById("loader-wrapper")) {
//       document.getElementById("loader-wrapper").style.display = "flex";
//       document.getElementsByTagName("html")[0].style.overflowY = "hidden";
//     }
//   }, 0);
// };

// export const onInitialClientRender = () => {
//   if (document.getElementById("loader-wrapper")) {
//     document.getElementById("loader-wrapper").style.display = "flex";
//     document.getElementsByTagName("html")[0].style.overflowY = "hidden";
//   }
// };

// export const onRouteUpdate = () => {
//   setTimeout(() => {
//     if (document.getElementById("loader-wrapper")) {
//       document.getElementById("loader-wrapper").style.display = "none";
//       document.getElementsByTagName("html")[0].style.overflowY = "auto";
//     }
//   }, 5000);
// };
